// Core
import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { differenceInCalendarDays, parseISO, format } from 'date-fns';
import { map, switchMap, take } from 'rxjs/operators';
import { zip } from 'rxjs';
import { CompareRptReceiptsDialogComponent } from '@app/@shared/components/receipt-transaction/dialogs/compare-rpt-receipts-dialog/compare-rpt-receipts-dialog.component';

// Models and Service
import { ApiResponse, ReceiptTransaction, VoidTransactionResponse, VoidTransactionRequest, PaginatedResponse, ReceiptTransactionResponse } from '@core/models';
import { LoyaltyService } from '@core/services/loyalty';
import { MemberService } from '@core/services/member';
import { UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DialogData, DialogService, RedemptionConstants, untilDestroyed, UserService } from '@app/@core';
import { Router } from '@angular/router';
import * as querystring from 'node:querystring';
import { PaymentTerminalReceiptDto } from '@core/models/receipt-transaction/rpt-transaction';
import { TenantService } from '../tenant.service';
import { isEqual, maxBy } from 'lodash';
import * as moment from 'moment';
import { ViewTransactionConstants } from '@app/pages/receipt-transaction/view-transaction/view-transaction.constants';
import { FeatureFlagService } from '@core/services/feature-flag.service';

interface InvoiceNumber {
  createdBy: string;
  createdDate: string;
  id: number;
  invoiceNumber: string;
  modifiedBy: string;
  modifiedDate: string;
  receiptTransactionId: number;
  version: number;
}

interface TransactionListingResponse extends PaginatedResponse {
  content: ReceiptTransactionResponse[];
}

/**
 *
 */
@Injectable({
  providedIn: 'root',
})
export class ReceiptTransactionService {
  public workflowConfig$: Observable<any> = null;
  public ignoredRptPaymentMethods$: Observable<any> = null;

  public closeCompareReceiptesSubject$ = new Subject();
  private isSubmittingSource = new BehaviorSubject<boolean>(false);

  constructor(
    private httpClient: HttpClient,
    private memberService: MemberService,
    private translateService: TranslateService,
    private domSanitizer: DomSanitizer,
    private loyaltyService: LoyaltyService,
    private dialogService: DialogService,
    protected router: Router,
    private tenantService: TenantService,
    public featureFlagService: FeatureFlagService,
    public userService: UserService
  ) {
    this.workflowConfig$ = this.getReceiptTransactionWorkflowConfig();
    this.ignoredRptPaymentMethods$ = this.getRptIgnoredPaymentMethods();

    this.loyaltyService.currentProgramme$.subscribe(() => {
      this.ignoredRptPaymentMethods$ = null;
      this.ignoredRptPaymentMethods$ = this.getRptIgnoredPaymentMethods();
    });
  }

  isQrCodeLoading = false;
  get transactionBackDateDays(): number {
    return this.loyaltyService.currentProgramme.daysForDateBackedReceiptSubmission;
  }
  receiptTransactionSource = new BehaviorSubject<ReceiptTransaction>(null);
  receiptTransactionDetailChange(data: ReceiptTransaction) {
    this.receiptTransactionSource.next(data);
  }

  receiptTenantListSource = new BehaviorSubject<any>([]);
  receiptTenantListChange(data: any) {
    this.receiptTenantListSource.next(data);
  }

  /**
   * Get transactions by searching params
   * @param params Search Params
   * @returns Receipt Transactions
   */
  getTransactions(params?: any): Observable<TransactionListingResponse> {
    return this.httpClient.get(`/transaction/search`, { params }).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  getDepositTransaction(params?: any): Observable<TransactionListingResponse> {
    return this.httpClient.get(`/transaction/searchLinkDepositTransaction`, { params }).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  /**
   * Get possible transactions by invoice number , tenantId and transaction date [Used in Upload Receipt by GE Page]
   * @param transaction Transaction Object
   * @returns List of transaction ID
   */
  getDuplicateTransaction(transaction: ReceiptTransaction | any): Observable<number[]> {
    // TODO: change to static format constant
    const params = {
      invoiceNumbers: transaction.invoiceNumbers.join(','),
      tenantId: transaction.tenantId,
      transactionDate: typeof transaction.transactionDate === 'string' ? format(parseISO(transaction.transactionDate), 'yyyy-MM-dd') : transaction.transactionDate.format('YYYY-MM-DD'),
    };
    if (this.featureFlagService.getFeatureFlag('RPT') && transaction.acquireReferenceNos) {
      params['acquireReferenceNos'] = transaction.acquireReferenceNos.join(',');
    }
    if (transaction.invoiceNumbers) {
      params['invoiceNumbers'] = transaction.invoiceNumbers.join(',');
    }
    return this.httpClient.get('/transaction/duplicate', { params }).pipe(map((response: ApiResponse) => response.data));
  }

  /**
   * Get possible transactions by transaction id, invoice number , tenantId and transaction date [Used in Transaction Audit Portal]
   * @param transaction Transaction Object
   * @returns List of transaction ID
   */
  getDuplicatedTransactionsWithId(transaction: ReceiptTransaction | any): Observable<number[]> {
    const params = {
      id: transaction.id,
      invoiceNumbers: transaction.invoiceNumbers,
      tenantId: transaction.tenantId,
      transactionDate: typeof transaction.transactionDate === 'string' ? format(parseISO(transaction.transactionDate), 'yyyy-MM-dd') : transaction.transactionDate.format('YYYY-MM-DD'),
    };
    if (transaction.invoiceNumbers) {
      params['invoiceNumbers'] = transaction.invoiceNumbers.join(',');
    }
    if (this.featureFlagService.getFeatureFlag('RPT') && transaction.rptAcquireReferenceNos?.length) {
      params['acquireReferenceNos'] = transaction.rptAcquireReferenceNos.join(',');
    }
    return this.httpClient.get('/transaction/duplicate', { params }).pipe(map((response: ApiResponse) => response.data));
  }

  /**
   * Get transaction detail by id
   * @param transactionId Transaction id
   * @returns
   */
  getTransactionById(transactionId: number | string): Observable<any> {
    return this.httpClient.get(`/transaction/details?id=${transactionId}`).pipe(
      map((response: ApiResponse) => {
        const res = response.data;
        res.invoiceNumbers = res.invoiceNumbers.map((invoice: InvoiceNumber) => {
          return invoice.invoiceNumber;
        });
        return res;
      })
    );
  }

  /**
   *
   * @param transactionId
   * @returns
   */
  getTransactionDetailById(transactionId: number | string): Observable<any> {
    return zip(
      this.getTransactionById(transactionId).pipe(
        switchMap((transaction) => {
          return forkJoin({
            memberDetail: this.memberService.getMemberDetail(transaction.guestId),
            memberAssociatedMember: this.memberService.getAssociatedMemberByGuestId(transaction.guestId),
          }).pipe(
            map((res) => ({
              transaction,
              memberDetail: res.memberDetail,
              memberAssociatedMember: res.memberAssociatedMember,
            }))
          );
        })
      )
    ).pipe((result: any) => result);
  }

  getLockedTransactionDetailById(id: string): Observable<any> {
    return zip(
      this.lockTransaction(id).pipe(
        switchMap((transaction: any) => {
          console.log(transaction);
          return forkJoin({
            memberDetail: this.memberService.getMemberDetail(transaction.guestId),
            memberAssociatedMember: this.memberService.getAssociatedMemberByGuestId(transaction.guestId),
          }).pipe(
            map((res) => {
              transaction.invoiceNumbers = transaction.invoiceNumbers.map((invoice: InvoiceNumber) => {
                return invoice.invoiceNumber;
              });

              return {
                transaction,
                memberDetail: res.memberDetail,
                memberAssociatedMember: res.memberAssociatedMember,
              };
            })
          );
        })
      )
    ).pipe((result: any) => result);
  }

  getReceiptThumbnail(id: string, type: 'thumbnail' | 'original'): Observable<SafeUrl> {
    return this.httpClient.get(`/transaction/receipt?receipt_id=${id}&type=${type}`, { responseType: 'blob' }).pipe(
      map((image: Blob) => {
        return this.domSanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(image));
      })
    );
  }

  getReceiptImageUrl(id: string, type: 'thumbnail' | 'original'): string {
    return `/transaction/receipt?receipt_id=${id}&type=${type}`;
  }

  rejectTransaction(params: any, isUpdated: boolean = false): Observable<any> {
    return this.httpClient.put(`/transaction/reject?updated=${isUpdated}`, params).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  approveTransaction(params: any, isUpdated: boolean = false): Observable<any> {
    return this.httpClient.put(`/transaction/approve?updated=${isUpdated}`, params).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  updateTransaction(params: any): Observable<any> {
    return this.httpClient.put(`/transaction/edit`, params).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  lockTransaction(params: any): Observable<any> {
    return this.httpClient.post(`/transaction/lock`, { id: Number(params) }).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  unlockTransaction(params: any): Observable<any> {
    return this.httpClient.post(`/transaction/unlock`, { id: Number(params) }).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  getNextPendingTransaction(transactionDate?: Date | string, memberId?: string): Observable<any> {
    return this.httpClient.get(`/transaction/nextpendingreceipt?memberId=${memberId}&transactionDateYmd=${transactionDate}`).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  getTransactionHistoryByGuestId(guestId: string, params?: any) {
    return this.httpClient.get(`/transaction/searchByGuest?guestId=${guestId}`, { params }).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  getTierMovementHistoryByGuestId(guestId: string, params?: any) {
    return this.httpClient.get(`/point/cycleHistory/tier?guestId=${guestId}`, { params }).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  getReceiptTransactionWorkflowConfig(): Observable<any> {
    if (this.workflowConfig$) {
      return this.workflowConfig$;
    }
    this.workflowConfig$ = this.httpClient.get(`/transaction/receiptWorkflowConfig`).pipe(
      map((res: any) => {
        return res.data;
      })
    ) as Observable<any>;
    return this.workflowConfig$;
  }

  getRptIgnoredPaymentMethods(): Observable<any> {
    if (this.ignoredRptPaymentMethods$) {
      return this.ignoredRptPaymentMethods$;
    }
    this.ignoredRptPaymentMethods$ = this.userService.systemConfig({
      key: 'RPT_EXCLUDED_PAYMENT_METHODS',
      businessUnit: this.loyaltyService.currentProgramme?.businessUnit,
      loyaltyProgram: this.loyaltyService.currentProgramme?.code,
    });
    return this.ignoredRptPaymentMethods$;
  }

  voidReceiptTransactionRequest(params: VoidTransactionRequest): Observable<VoidTransactionResponse> {
    return this.httpClient.post(`/transaction/voidRequest`, params).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  isBackdateTransaction(transactionDate: string, uploadedDate: Date = new Date()) {
    return differenceInCalendarDays(uploadedDate, new Date(transactionDate)) > this.loyaltyService.currentProgramme.daysForDateBackedReceiptSubmission;
  }

  createTransaction(formData: any): Observable<any> {
    return this.httpClient.post(`/transaction`, formData).pipe(
      map((response: ApiResponse) => {
        return response;
      })
    );
  }

  verifyApprovedTransaction(params: any) {
    return this.httpClient.put(`/transaction/verify`, params).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  checkLinkDepositValidity(fg: UntypedFormGroup, transactionIndex?: number): string[] {
    const errorFields: string[] = [];
    const linkedDepositTransactionId: ReceiptTransaction[] | null = fg.get('linkDepositTransactionId')?.value;
    const transactionAmount: number = Number(fg.get('transactionAmount')?.value);
    const receiptAmount: number = Number(fg.get('receiptAmount')?.value);
    if ((transactionAmount < 0 || receiptAmount < 0) && !linkedDepositTransactionId?.length) {
      transactionIndex !== null && transactionIndex !== undefined
        ? errorFields.push(`Transaction ${transactionIndex + 1}: ${this.translateService.instant('RECEIPT_TRANSACTIONS.ERROR.NEGATIVE_AMOUNT_IN_TRANSACTION')}`)
        : errorFields.push('RECEIPT_TRANSACTIONS.ERROR.NEGATIVE_AMOUNT_IN_TRANSACTION');
      return errorFields;
    }

    // if ((transactionAmount < receiptAmount) && linkedDepositTransactionId?.length) {
    //   (transactionIndex !== null && transactionIndex !== undefined) ? errorFields.push(`Transaction ${transactionIndex + 1}: ${this.translateService.instant('RECEIPT_TRANSACTIONS.ERROR.ELEC_AMOUNT_GREATER_THAN_TRANSACTION_AMOUNT')}`)
    //   : errorFields.push('RECEIPT_TRANSACTIONS.ERROR.ELEC_AMOUNT_GREATER_THAN_TRANSACTION_AMOUNT');
    //   return errorFields;
    // }

    if (linkedDepositTransactionId?.length) {
      if (
        !linkedDepositTransactionId
          .map((transaction) => {
            return transaction.status;
          })
          .every((status) => {
            return status === 'APPROVED';
          })
      ) {
        transactionIndex !== null && transactionIndex !== undefined
          ? errorFields.push(`Transaction ${transactionIndex + 1}: ${this.translateService.instant('RECEIPT_TRANSACTIONS.ERROR.NON_APPROVED_DEPOSIT')}`)
          : errorFields.push('RECEIPT_TRANSACTIONS.ERROR.NON_APPROVED_DEPOSIT');
        return errorFields;
      }

      const totalDepositTransactionAmount = linkedDepositTransactionId.reduce((prev, current) => {
        return prev + current.transactionAmount;
      }, 0);

      const totalDepositReceiptAmount = linkedDepositTransactionId.reduce((prev, current) => {
        return prev + current.receiptAmount;
      }, 0);

      if (transactionAmount < 0 && totalDepositTransactionAmount < Number(-transactionAmount)) {
        transactionIndex !== null && transactionIndex !== undefined
          ? errorFields.push(`Transaction ${transactionIndex + 1}: ${this.translateService.instant('RECEIPT_TRANSACTIONS.ERROR.EXCEED_DEPOSIT_TRANSACTION_AMOUNT')}`)
          : errorFields.push('RECEIPT_TRANSACTIONS.ERROR.EXCEED_DEPOSIT_TRANSACTION_AMOUNT');
      }

      if (receiptAmount < 0 && totalDepositReceiptAmount < Number(-receiptAmount)) {
        transactionIndex !== null && transactionIndex !== undefined
          ? errorFields.push(`Transaction ${transactionIndex + 1}: ${this.translateService.instant('RECEIPT_TRANSACTIONS.ERROR.EXCEED_DEPOSIT_RECEIPT_AMOUNT')}`)
          : errorFields.push('RECEIPT_TRANSACTIONS.ERROR.EXCEED_DEPOSIT_RECEIPT_AMOUNT');
      }
    }
    return errorFields;
  }

  /**
   * For GE Upload Receipt Flow
   * @param params Same with normal upload receipt flow
   */
  saveAndApproveTransaction(params: any): Observable<any> {
    return this.httpClient.post(`/transaction/saveAndApprove`, params).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  /**
   *
   * @returns
   */
  getActiveRewardBySpendingList(): Observable<any> {
    return this.httpClient.get(`/campaign/promotion/haveActiveRewardBySpending`).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }
  /**
   * Get possible deposit transactions by invoice number , tenantId and transaction date and so on
   * @param transaction Transaction Object
   * @returns List of transaction ID
   */
  getLinkDepositTransaction(params: any): Observable<TransactionListingResponse> {
    return this.httpClient.get(`/transaction/searchLinkDepositTransaction`, { params }).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  //reeipt spot check
  getSpotCheckSearch(params: any) {
    return this.httpClient.get(`/transaction/check/search`, { params }).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  // receipt spot check export
  exportTransactionSpotCheck(params: any) {
    return this.httpClient
      .post(`/report/exportTransactionSpotCheck`, params, {
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  // reeipt spot check next
  getSpotCheckNext(params: any) {
    this.httpClient
      .get(`/transaction/check/next`, { params })
      .pipe(
        map((response: ApiResponse) => {
          return response;
        })
      )
      .subscribe((res: any) => {
        if (res?.message != 'E150039') {
          this.router.navigateByUrl(`admin/receipt-transaction/view/${res.data}?type=spotcheck`);
        } else {
          const dialogData: DialogData = {
            content: 'Spot check completed',
            yesLabel: 'COMMON.LABEL.CONFIRM',
            isHideNoButton: true,
            yesCallback: (dialogRef) => {
              dialogRef.close();
            },
          };
          this.dialogService.showConfirmationDialog(dialogData).subscribe();
        }
      });
  }

  getRptTransaction(qrCode: string) {
    return this.httpClient.get(`/transaction/paymentTerminal/qrCode?qrCode=${qrCode}`).pipe(
      map((response: ApiResponse<PaymentTerminalReceiptDto>) => {
        return response.data;
      })
    );
  }

  postRptTransaction(qrCode: string, guestId: string) {
    return this.httpClient.post(`/transaction/paymentTerminal/upload`, { skipDuplicationChecking: true, qrCode, guestId }).pipe(
      map((response: ApiResponse<ReceiptTransaction>) => {
        return response.data;
      })
    );
  }

  // isRpt
  isRptReceipt(val: any, tenantData: any[] = []) {
    if (val?.rptReceipt) {
      return true;
    }
    const allUsingPaymentTerminalId = tenantData.filter((item: any) => item.usingPaymentTerminal).map((item: any) => item.id);
    if (allUsingPaymentTerminalId.includes(val?.tenantId)) {
      return true;
    }
    return false;
  }

  // acquirerReferenceNo
  acquirerReferenceNo(params: any) {
    return this.httpClient.get(`/transaction/paymentTerminal/acquireReferenceNo`, { params }).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }
  getAcquirerReferenceNo(params: any, currentValue: any, fun?: (d?: any) => void) {
    this.acquirerReferenceNo({
      ...params,
      paymentAmount: Number(currentValue?.receiptAmount),
      paymentMethod: currentValue?.paymentMethod,
      tenantId: currentValue?.tenantId,
      transactionDate: moment(currentValue?.transactionDate).format('YYYY-MM-DD'),
      transactionAmount:Number(currentValue?.transactionAmount)
    }).subscribe((res: any) => {
      if (!res || Number(currentValue?.tenantId) != Number(res?.shop?.oneCrmId)) {
        fun && fun();
        return false;
      }
      const { newBackValue, currentValueData } = this.isRptSame(currentValue, res);
      const rptAcquireReferenceNos: string[] = res?.payments?.map((item: any) => item.acquirerRefNo);
      const transactionTime = moment(res?.submissionTime).format('HH:mm');
      const rptAmount = {
        rptDiscountedAmount: res.evoucherTotal,
        rptExcludedAmount: res.excludedAmount,
        rptMallDollarDiscountedAmount: res.mallDollarTotal,
      };
      const merchantTransactionIds = res?.merchantTransactionIds?.map(Number) || [];
      /*
        Replacement dialog is shown when one of the following fields mismatch with data from RPT:
          - transactionDate
          - transactionAmount
          - receiptAmount
          - paymentMethod
          - acquirerReferenceNo (RPT API is capable to return receipts with different ARN but having the same values for other fields, so ARN is included in checking)
       */
      if (isEqual(newBackValue, currentValueData) && rptAcquireReferenceNos.includes(params.acquireReferenceNo[0])) {
        fun &&
          fun({
            ...newBackValue,
            ...rptAmount,
            transactionDate: moment(res?.submissionTime).toISOString(),
            rptTransactionId: res?.transactionRefNo,
            rptExternalSourceId: res?.id,
            withTransactionTime: transactionTime && transactionTime.trim() !== '' ? true : false,
            merchantTransactionIds,
            rptAcquireReferenceNos,
          });
      } else {
        const dialogData = {
          id: 'rptcompare-dialog',
          width: '810px',
          height: '700px',
          maxHeight: '90vh',
          panelClass: 'outer-dialog-container',
          yesCallback: (dialogRef: any) => {
            dialogRef.close();
            fun &&
              fun({
                ...newBackValue,
                ...rptAmount,
                transactionDate: moment(res?.submissionTime).toISOString(),
                rptTransactionId: res?.transactionRefNo,
                rptExternalSourceId: res?.id,
                withTransactionTime: transactionTime && transactionTime.trim() !== '' ? true : false,
                merchantTransactionIds,
                rptAcquireReferenceNos,
              });
          },
          noCallback: (dialogRef: any) => {
            dialogRef.close();
            fun && fun();
          },
          customData: {
            currentValue: {
              // value inputted by user
              ...currentValue,
              transactionDate: moment(currentValue?.transactionDate).format('DD-MM-YYYY'),
            },
            backValue: {
              // value from RPT
              ...res,
              ...newBackValue,
              transactionDate: moment(res?.submissionTime).format('DD-MM-YYYY'),
              transactionTime: moment(res?.submissionTime).format('HH:mm'),
            },
          },
          disableClose: true,
        };
        this.dialogService
          .showDialog(dialogData, CompareRptReceiptsDialogComponent)
          .pipe(take(1))
          .subscribe((dialogRef) => {});
      }
    });
  }

  // rpt 是否相同
  isRptSame(value: any, backValue: any) {
    const rptPayments: any[] = backValue?.payments;
    const largestRptPayment = rptPayments
      .filter((payment) => payment.status == 'success')
      .sort((payment1, payment2) => payment2.paymentAmount - payment1.paymentAmount)
      .at(0);
    const newBackValue = {
      receiptAmount: Number(backValue?.receiptAmount),
      transactionAmount: Number(backValue?.transactionAmount),
      tenantId: Number(backValue?.shop?.oneCrmId),
      paymentMethod: largestRptPayment?.paymentMethod?.name,
      transactionDate: moment(backValue?.submissionTime).format('YYYY-MM-DD'),
    };
    const currentValueData = {
      receiptAmount: Number(value?.receiptAmount),
      transactionAmount: Number(value?.transactionAmount),
      tenantId: Number(value?.tenantId),
      paymentMethod: value?.paymentMethod,
      transactionDate: moment(value?.transactionDate).format('YYYY-MM-DD'),
    };
    return { newBackValue, currentValueData };
  }

  // tenant is consignment or master
  isNotAllowSubmit(minTransactionAmtPerUploadedReceipt: any, masterMerchantIdConsignmentId: any[], formValue: any) {
    const tenantId = formValue?.tenantId;
    const receiptAmount = formValue?.receiptAmount;
    const transactionDate = formValue?.transactionDate;
    const isToday = moment(transactionDate).isSame(moment(), 'day');
    console.log('3==32', minTransactionAmtPerUploadedReceipt, receiptAmount, Number(receiptAmount) >= Number(minTransactionAmtPerUploadedReceipt));
    if (masterMerchantIdConsignmentId.includes(tenantId)) {
      if (!(isToday || Number(receiptAmount) >= Number(minTransactionAmtPerUploadedReceipt))) {
        const dialogData: DialogData = {
          title: 'Error',
          content: `Not allow submit day back consignment with Electronic Receipt Amount less than $${minTransactionAmtPerUploadedReceipt}`,
          width: '370px',
          yesCallback: (dialogRef) => {
            dialogRef.close();
          },
        };
        this.dialogService.showErrorDialog(dialogData).subscribe();
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}
